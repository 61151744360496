import React, { useState, useEffect } from "react";
import { Box, List, ListItem, ListSubheader, Typography, ButtonGroup, Button, IconButton } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { FavoriteButton } from "../FavoriteButton/FavoriteButton";
import { getDocuments } from "../../api";
import { format, isToday, isYesterday, parseISO } from "date-fns";
import { SavedDocument } from "../../api/models";
import { OptionsMenu } from "../OptionsMenu/OptionsMenu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SourceActionPopup } from "../SourceActionPopup";

interface DocMenuProps {
    open: boolean;
    onClose: () => void;
}

type ViewMode = "recent" | "starred";

export const DocMenu: React.FC<DocMenuProps> = ({ open, onClose }) => {
    const navigate = useNavigate();
    const { getToken } = useAuth();
    const [viewMode, setViewMode] = useState<ViewMode>("recent");
    const [documents, setDocuments] = useState<SavedDocument[]>([]);
    const [favoriteStates, setFavoriteStates] = useState<Record<string, boolean>>({});
    const [forceUpdate, setForceUpdate] = useState(0);
    const [activeMenuId, setActiveMenuId] = useState<string | null>(null);
    const [selectedDoc, setSelectedDoc] = useState<SavedDocument | null>(null);
    const isMobile = window.innerWidth < 900; // md breakpoint is 900px

    const loadDocuments = async () => {
        try {
            const token = await getToken({ template: "Standard" });
            const response = await getDocuments(token, false, false);
            const responseText = await response.text();

            if (responseText.trim().startsWith("{")) {
                const data = JSON.parse(responseText);
                const docs = data.documents || [];
                setDocuments(docs);

                // Initialize favorite states
                const newFavoriteStates: Record<string, boolean> = {};
                docs.forEach((doc: SavedDocument) => {
                    newFavoriteStates[doc.doc_id] = !!doc.favourite;
                });
                setFavoriteStates(newFavoriteStates);
            }
        } catch {
            // Silently fail or handle error appropriately
        }
    };

    useEffect(() => {
        if (open) {
            loadDocuments();
        }
    }, [open]);

    const handleFavoriteChange = (docId: string, isFavorite: boolean) => {
        // Update favorite states
        setFavoriteStates(prev => ({
            ...prev,
            [docId]: isFavorite
        }));

        // Update documents list
        setDocuments(prevDocs => {
            const updatedDocs = prevDocs.map(doc => {
                if (doc.doc_id === docId) {
                    return {
                        ...doc,
                        favourite: isFavorite ? new Date().toISOString() : null
                    };
                }
                return doc;
            });
            return updatedDocs;
        });

        // Force a re-render of filtered documents
        setForceUpdate(prev => prev + 1);
    };

    // Filter documents based on current view mode and favorite states
    const filteredDocuments = React.useMemo(() => {
        if (viewMode === "starred") {
            return documents.filter(doc => favoriteStates[doc.doc_id]);
        }
        return documents;
    }, [documents, favoriteStates, viewMode, forceUpdate]);

    const groupDocumentsByDate = (docs: SavedDocument[]) => {
        return docs.reduce((groups: Record<string, SavedDocument[]>, doc) => {
            const dateStr = doc.viewed || doc.updated;
            if (!dateStr) return groups;

            const date = parseISO(dateStr);
            let formattedDate;

            if (isToday(date)) {
                formattedDate = "Today";
            } else if (isYesterday(date)) {
                formattedDate = "Yesterday";
            } else {
                formattedDate = format(date, "MMMM dd, yyyy");
            }

            if (!groups[formattedDate]) {
                groups[formattedDate] = [];
            }

            groups[formattedDate].push(doc);
            return groups;
        }, {});
    };

    const groupedDocuments = React.useMemo(() => {
        return groupDocumentsByDate(filteredDocuments);
    }, [filteredDocuments]);

    // Add effect to reset activeMenuId when DocMenu closes
    useEffect(() => {
        if (!open) {
            setActiveMenuId(null);
        }
    }, [open]);

    const handleMenuToggle = (docId: string, isOpen: boolean) => {
        setActiveMenuId(isOpen ? docId : null);
    };

    // Close options menu when clicking away from DocMenu
    const handleClickAway = () => {
        setActiveMenuId(null);
        onClose();
    };

    // Add handler for mobile document options
    const handleDocumentOptions = (doc: SavedDocument) => {
        if (isMobile) {
            // Create a properly formatted document object
            const formattedDoc = {
                ...doc.data,
                doc_id: doc.doc_id,
                id: doc.doc_id,
                name: doc.data.name,
                url: doc.data.pdf_url || doc.data.url,
                summary1: doc.data.summary1
            };
            setSelectedDoc({ ...doc, data: formattedDoc });
        }
    };

    // Add handler to close the popup
    const handleClosePopup = () => {
        setSelectedDoc(null);
    };

    if (!open) return null;

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <>
                <Box
                    sx={{
                        position: "fixed",
                        left: {
                            xs: 0,
                            md: "220px"
                        },
                        top: 0,
                        height: "100vh",
                        width: {
                            xs: "100vw",
                            md: "400px"
                        },
                        backgroundColor: "background.surface",
                        borderRight: "1px solid",
                        borderColor: "divider",
                        overflowY: "auto",
                        zIndex: 1100,
                        boxShadow: "rgba(0, 0, 0, 0.1) 2px 0px 4px"
                    }}
                >
                    <Box
                        sx={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            backgroundColor: "background.surface",
                            borderBottom: "1px solid",
                            borderColor: "divider",
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2
                        }}
                    >
                        {/* Mobile back button - updated to match MobileHistoryMenu style */}
                        <Box
                            sx={{
                                display: { xs: "flex", md: "none" },
                                alignItems: "center",
                                gap: 1
                            }}
                        >
                            <IconButton onClick={onClose} variant="plain">
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography level="title-lg">Documents</Typography>
                        </Box>

                        {/* Existing button group */}
                        <ButtonGroup variant="soft" size="sm" sx={{ alignSelf: "center" }}>
                            <Button
                                onClick={() => {
                                    setViewMode("recent");
                                    setForceUpdate(prev => prev + 1);
                                }}
                                variant={viewMode === "recent" ? "solid" : "soft"}
                                color={viewMode === "recent" ? "primary" : "neutral"}
                            >
                                Recent
                            </Button>
                            <Button
                                onClick={() => {
                                    setViewMode("starred");
                                    setForceUpdate(prev => prev + 1);
                                }}
                                variant={viewMode === "starred" ? "solid" : "soft"}
                                color={viewMode === "starred" ? "primary" : "neutral"}
                            >
                                Starred
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <List>
                            {Object.entries(groupedDocuments).map(([date, docs]) => (
                                <ListItem nested key={date}>
                                    <ListSubheader>{date}</ListSubheader>
                                    <List>
                                        {docs.map(doc => (
                                            <ListItem key={doc.id}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        width: "100%",
                                                        alignItems: "flex-start",
                                                        gap: 1,
                                                        py: 0.5
                                                    }}
                                                >
                                                    <FavoriteButton
                                                        id={doc.doc_id}
                                                        type="document"
                                                        initialFavoriteState={favoriteStates[doc.doc_id]}
                                                        size="sm"
                                                        onFavoriteChange={isFavorite => handleFavoriteChange(doc.doc_id, isFavorite)}
                                                    />
                                                    <Box
                                                        onClick={() => {
                                                            navigate("/pdf-chat", {
                                                                state: {
                                                                    pdfUrl: doc.data.pdf_url || doc.data.url,
                                                                    documentId: doc.doc_id,
                                                                    documentName: doc.data.name,
                                                                    fullDocument: {
                                                                        ...doc.data,
                                                                        doc_id: doc.doc_id,
                                                                        id: doc.doc_id
                                                                    }
                                                                }
                                                            });
                                                            onClose();
                                                        }}
                                                        sx={{
                                                            cursor: "pointer",
                                                            p: 1,
                                                            borderRadius: 1,
                                                            flexGrow: 1,
                                                            "&:hover": {
                                                                backgroundColor: "action.hover"
                                                            }
                                                        }}
                                                    >
                                                        <Typography
                                                            level="body-sm"
                                                            sx={{
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient: "vertical",
                                                                overflow: "hidden",
                                                                lineHeight: "1.2em",
                                                                maxHeight: "2.4em",
                                                                mb: 0.5,
                                                                color: "primary.main",
                                                                textDecoration: "none",
                                                                "&:hover": {
                                                                    textDecoration: "underline"
                                                                }
                                                            }}
                                                        >
                                                            {doc.data.name || "Untitled Document"}
                                                        </Typography>
                                                        <Typography level="body-xs" color="neutral">
                                                            {doc.viewed
                                                                ? `Viewed ${format(parseISO(doc.viewed), "h:mm a")}`
                                                                : `Updated ${format(parseISO(doc.updated as string), "h:mm a")}`}
                                                        </Typography>
                                                    </Box>
                                                    {isMobile ? (
                                                        <IconButton onClick={() => handleDocumentOptions(doc)} variant="plain" color="neutral" size="sm">
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <OptionsMenu
                                                            docId={doc.doc_id}
                                                            docUrl={doc.data.url}
                                                            docName={doc.data.name}
                                                            summary1={doc.data.summary1}
                                                            pdf_url={doc.data.pdf_url}
                                                            isActive={activeMenuId === doc.doc_id}
                                                            onMenuToggle={isOpen => handleMenuToggle(doc.doc_id, isOpen)}
                                                        />
                                                    )}
                                                </Box>
                                            </ListItem>
                                        ))}
                                    </List>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>

                {/* Add SourceActionPopup */}
                {/*@ts-expect-error i cant deal with this*/}
                <SourceActionPopup open={!!selectedDoc} onClose={handleClosePopup} resultDocument={selectedDoc?.data} />
            </>
        </ClickAwayListener>
    );
};
