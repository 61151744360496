import { Button, ButtonGroup, ToggleButtonGroup } from "@mui/joy";
import { FilterRequest } from "../../api";
import styles from "./FilterButtons.module.css";

export const FilterButtons = ({
    filters,
    onUpdateFilters,
    viewMode,
    onViewModeChange
}: {
    filters: FilterRequest;
    onUpdateFilters: (key: string, value: string[]) => void;
    viewMode?: "normal" | "simple";
    onViewModeChange?: (event: React.MouseEvent<HTMLElement>, newMode: "normal" | "simple" | null) => void;
}) => {
    return (
        <div className={styles.filterButtons}>
            <ButtonGroup
                aria-label="document type filter"
                sx={{
                    marginRight: 2,
                    "& .MuiButton-root": {
                        '&.Mui-selected, &[aria-pressed="true"]': {
                            backgroundColor: "#1ea8a !important",
                            color: "white !important"
                        }
                    }
                }}
            >
                <Button variant={!filters.doc_type || filters.doc_type.length === 0 ? "solid" : "outlined"} onClick={() => onUpdateFilters("doc_type", [])}>
                    All
                </Button>
                <Button variant={filters.doc_type?.includes("judgement") ? "solid" : "outlined"} onClick={() => onUpdateFilters("doc_type", ["judgement"])}>
                    Judgments
                </Button>
                <Button variant={filters.doc_type?.includes("legislation") ? "solid" : "outlined"} onClick={() => onUpdateFilters("doc_type", ["legislation"])}>
                    Legislation
                </Button>
            </ButtonGroup>

            {viewMode !== undefined && onViewModeChange && (
                <ToggleButtonGroup
                    value={viewMode}
                    onChange={onViewModeChange}
                    sx={{
                        "& .Mui-selected": {
                            backgroundColor: "#1e3a8a",
                            color: "white !important"
                        }
                    }}
                >
                    <Button value="normal">Normal</Button>
                    <Button value="simple">Simple</Button>
                </ToggleButtonGroup>
            )}
        </div>
    );
};
