const BACKEND_URI = "";

import { ChatAppRequest, SearchRequest, Document } from "./models";

function getHeaders(idToken: string | null): Record<string, string> {
    const headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    if (idToken) {
        headers["Authorization"] = `Bearer ${idToken}`;
    }

    return headers;
}

export async function chatApi(request: ChatAppRequest, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function chatPdfApi(request: ChatAppRequest, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chatpdf`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}/content/${citation}`;
}

export async function listConversations(idToken: string | null, page: number): Promise<Response> {
    const resp = await fetch(`${BACKEND_URI}/conversation?page=${page}`, {
        method: "GET",
        headers: getHeaders(idToken)
    });
    return resp;
}

export async function getConversation(id: string, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/conversation/${id}`, {
        method: "GET",
        headers: getHeaders(idToken)
    });
}

export async function getMonthlyMessagesCount(idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/messages/monthly-count`, {
        method: "GET",
        headers: getHeaders(idToken)
    });
}

export async function getUser(idToken: string | null, invite_code?: string | null, country?: string | null): Promise<Response> {
    let url = `${BACKEND_URI}/user`;
    const params = new URLSearchParams();
    if (invite_code) {
        params.append("invite_code", invite_code);
    }
    if (country) {
        params.append("country", country);
    }
    if (params.toString()) {
        url += `?${params.toString()}`;
    }
    console.log(url);
    return await fetch(url, {
        method: "GET",
        headers: getHeaders(idToken)
    });
}

export async function searchRequest(request: SearchRequest, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/search`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function submitFeedback(messageId: number, isPositive: boolean, feedbackText: string | null, token: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/feedback`, {
        method: "POST",
        headers: getHeaders(token),
        body: JSON.stringify({
            message_id: messageId,
            is_positive: isPositive,
            feedback_text: feedbackText
        })
    });
}

export async function toggleConversationFavorite(id: string, idToken: string | null): Promise<Response> {
    return await fetch(`${BACKEND_URI}/conversation/${id}/favorite`, {
        method: "POST",
        headers: getHeaders(idToken)
    });
}

export const getDocuments = async (token: string | null, _hideDeleted: boolean = false, starred: boolean = false): Promise<Response> => {
    if (!token) {
        throw new Error("No authentication token provided");
    }

    const headers = getHeaders(token); // Use the existing getHeaders function

    const queryParams = new URLSearchParams({
        starred: starred.toString(),
        _t: Date.now().toString()
    });

    return await fetch(`${BACKEND_URI}/documents?${queryParams.toString()}`, {
        method: "GET",
        headers: headers
    });
};

export const toggleDocumentFavorite = async (docId: string, token: string | null): Promise<Response> => {
    if (!token) {
        throw new Error("No authentication token provided");
    }

    return await fetch(`${BACKEND_URI}/document/${docId}/favourite`, {
        method: "POST",
        headers: getHeaders(token)
    });
};

export async function markDocumentViewed(docId: string, documentData: Partial<Document>, idToken: string | null): Promise<Response> {
    const headers = getHeaders(idToken);
    return await fetch(`${BACKEND_URI}/document/${docId}/view`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(documentData)
    });
}
