import * as React from "react";
import { Button, Position, Tooltip, Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { HighlightArea, highlightPlugin, MessageIcon, RenderHighlightTargetProps, RenderHighlightsProps } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { forwardRef, Ref, useEffect, useImperativeHandle } from "react";
import { Quote } from "../../pages/pdfChat/types";

interface PDFViewerProps {
    fileUrl: string;
    pageNumber?: number;
    onNoteUpdate?: (query: string, source: string, pageNumber: number | null, answer: string) => void;
    currentQuery?: string | null;
    currentAnswer?: string | null;
    currentSource?: string | null;
    currentPageNumber?: number | null;
    quotes: Quote[];
}

export interface PDFViewerHandle {
    jumpToHighlightArea: (area: HighlightArea) => void;
}

const PDFViewerComponent = ({ fileUrl, currentPageNumber, quotes }: PDFViewerProps, ref: Ref<PDFViewerHandle>) => {
    const notesContainerRef = React.useRef<HTMLDivElement>(null);
    const noteEles = React.useRef(new Map<number, HTMLElement>()).current;

    // Add state to track PDF loading
    const [isPdfLoaded, setIsPdfLoaded] = React.useState(false);

    // Add state to track if viewer is ready for jumping
    const [isViewerReady, setIsViewerReady] = React.useState(false);
    const pendingJumpRef = React.useRef<HighlightArea | null>(null);

    // Update the document load handler
    const handleDocumentLoad = () => {
        setIsPdfLoaded(true);
        // Add a delay before setting viewer ready
        setTimeout(() => {
            setIsViewerReady(true);
            // If there's a pending jump, execute it
            if (pendingJumpRef.current) {
                highlightPluginInstance.jumpToHighlightArea(pendingJumpRef.current);
                pendingJumpRef.current = null;
            }
        }, 1000); // Adjust this delay as needed
    };

    useEffect(() => {
        if (quotes.length > 0) {
            if (quotes[quotes.length - 1].highlights.length > 0) {
                jumpToHighlightArea(quotes[quotes.length - 1].highlights[0]);
            }
        }
    }, [quotes]);

    const renderHighlightTarget = (props: RenderHighlightTargetProps) => (
        <div
            style={{
                background: "#eee",
                display: "flex",
                position: "absolute",
                // eslint-disable-next-line
                left: `${props.selectionRegion.left}%`,
                // eslint-disable-next-line
                top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                transform: "translate(0, 8px)",
                zIndex: 1
            }}
        >
            <Tooltip
                position={Position.TopCenter}
                target={
                    // eslint-disable-next-line
                    <Button onClick={props.toggle}>
                        <MessageIcon />
                    </Button>
                }
                content={() => <div style={{ width: "100px" }}>Add a note</div>}
                offset={{ left: 0, top: -8 }}
            />
        </div>
    );

    const jumpToQuote = (quote: Quote) => {
        const notesContainer = notesContainerRef.current;
        if (notesContainer) {
            const noteElement = noteEles.get(Number(quote.id));
            if (noteElement) {
                notesContainer.scrollTop = noteElement.getBoundingClientRect().top;
            }
        }
    };

    const renderHighlights = (props: RenderHighlightsProps) => {
        const renderedAreas = new Set<string>();

        return (
            <div>
                {quotes.map((quote, index) => (
                    <React.Fragment key={index}>
                        {quote.highlights
                            // eslint-disable-next-line
                            .filter(area => area.pageIndex === props.pageIndex)
                            .map((area, idx) => {
                                const areaKey = `${area.pageIndex}-${area.top}-${area.left}-${area.height}-${area.width}`;
                                if (renderedAreas.has(areaKey)) {
                                    return null;
                                }
                                renderedAreas.add(areaKey);
                                return (
                                    <div
                                        key={idx}
                                        style={Object.assign(
                                            {},
                                            {
                                                background: "rgba(255, 255, 0, 0.5)",
                                                borderRadius: "5px",
                                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)"
                                            },
                                            // eslint-disable-next-line
                                            props.getCssProperties(area, props.rotation)
                                        )}
                                        onClick={() => jumpToQuote(quote)}
                                    />
                                );
                            })}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget,
        renderHighlights
    });

    // Update the jumpToHighlightArea function
    const jumpToHighlightArea = (area: HighlightArea) => {
        if (!isPdfLoaded || !isViewerReady) {
            // Store the jump request
            pendingJumpRef.current = area;

            // Wait for both PDF to load and viewer to be ready
            const checkReady = setInterval(() => {
                if (isPdfLoaded && isViewerReady) {
                    clearInterval(checkReady);
                    highlightPluginInstance.jumpToHighlightArea(area);
                    pendingJumpRef.current = null;
                }
            }, 100);

            // Set a timeout to prevent infinite checking
            setTimeout(() => clearInterval(checkReady), 10000);
        } else {
            highlightPluginInstance.jumpToHighlightArea(area);
        }
    };

    useImperativeHandle(ref, () => ({
        jumpToHighlightArea
    }));

    React.useEffect(() => {
        return () => {
            noteEles.clear();
        };
    }, []);

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: defaultTabs => {
            if (window.innerWidth <= 768) {
                return [];
            }
            return defaultTabs;
        }
    });

    React.useEffect(() => {
        const handleResize = () => {
            const viewer = document.querySelector(".rpv-core__viewer");
            if (viewer) {
                viewer.dispatchEvent(new Event("resize"));
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div
            style={{
                height: "100%"
            }}
        >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                    defaultScale={1.3}
                    fileUrl={fileUrl}
                    plugins={[highlightPluginInstance, defaultLayoutPluginInstance]}
                    initialPage={currentPageNumber ? currentPageNumber - 1 : 0}
                    onDocumentLoad={handleDocumentLoad}
                />
            </Worker>
        </div>
    );
};

export const PDFViewer = forwardRef<PDFViewerHandle, PDFViewerProps>(PDFViewerComponent);
