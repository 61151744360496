import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { PDFChatView } from "./PDFChatView";
import { ChatAppRequest, ChatAppResponse, ChatAppResponseOrError, chatPdfApi, getConversation, ResponseMessage } from "../../api";
import { Quote } from "./types";
import { useAuth } from "@clerk/clerk-react";
import { useDocumentView } from "../../components/useDocumentView/useDocumentView";

const PDFChatViewWrapper = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pdfUrl, documentId, documentName } = location.state || {};
    const [searchParams] = useSearchParams();
    const [conversationId, setConversationId] = useState<string | undefined>(undefined);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const { getToken } = useAuth();
    const lastQuestionRef = useRef<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // eslint-disable-next-line
    const [error, setError] = useState<any>();
    const [quotes, setQuotes] = useState<Quote[]>([]);
    const { handleDocumentView } = useDocumentView();

    useEffect(() => {
        if (conversationId === searchParams.get("conversationId")) {
            return;
        } else if (searchParams.get("conversationId")) {
            loadConversation(searchParams.get("conversationId"));
            setConversationId(searchParams.get("conversationId") || undefined);
        }
    }, [searchParams]);

    // Add useEffect to mark document as viewed when loaded
    useEffect(() => {
        if (documentId && pdfUrl) {
            const documentData = {
                doc_id: documentId,
                name: documentName,
                url: pdfUrl,
                pdf_url: pdfUrl
            };
            handleDocumentView(documentId, documentData);
        }
    }, [documentId, pdfUrl, documentName]);

    const handleSendQuestion = async (question: string): Promise<void> => {
        lastQuestionRef.current = question;
        setIsLoading(true);
        setError(undefined);

        const messages: ResponseMessage[] = answers.flatMap(a => [
            { content: a[0], role: "user" },
            { content: a[1].choices[0].message.content, role: "assistant" }
        ]);

        try {
            const request: ChatAppRequest = {
                conversation_id: conversationId || undefined,
                messages: [...messages, { content: question, role: "user" }],
                search: question,
                stream: false,
                context: {
                    chat_type: "pdf",
                    overrides: {
                        top: 4,
                        suggest_followup_questions: true,
                        filters: {
                            doc_id: [documentId]
                        }
                    }
                },
                session_state: null
            };

            const response = await chatPdfApi(request, await getToken({ template: "Standard" }));
            if (!response.body) {
                throw new Error("No response body");
            }

            const parsedResponse: ChatAppResponseOrError = await response.json();
            if (response.status > 299 || !response.ok) {
                throw new Error(parsedResponse.error || "Unknown error");
            }

            // @ts-expect-error - parsedResponse is not null
            const quoteResponse: Quote[] = parsedResponse.choices?.length > 0 ? JSON.parse(parsedResponse.choices[0].context.quotes) : [];
            setQuotes(quoteResponse);

            // @ts-expect-error - parsedResponse is not null
            parsedResponse.choices[0].message.content = parsedResponse.choices[0].message.content.replace(
                /<\[(\d+)\]>/g,
                (_, p1) => `<Quote index="${p1}" > </Quote>`
            );

            // @ts-expect-error - parsedResponse is not null
            setAnswers([...answers, [question, parsedResponse, quotes]]);
            setConversationId(String(parsedResponse.conversation_id));

            // Add new conversation to history if this is the first message
            if (answers.length === 0 && parsedResponse?.choices && parsedResponse.choices.length > 0) {
                const newConversation = {
                    id: parsedResponse.conversation_id,
                    created: new Date().toISOString(),
                    type: "pdf",
                    messages: [
                        {
                            question: question,
                            answer: parsedResponse.choices[0].message.content,
                            created: new Date().toISOString()
                        }
                    ],
                    favorite: false,
                    doc_id: documentId,
                    url: pdfUrl
                };

                // Dispatch event to update sidebar
                window.dispatchEvent(
                    new CustomEvent("newConversation", {
                        detail: newConversation
                    })
                );
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const loadConversation = async (conversationId: string | null) => {
        if (!conversationId) {
            return;
        }
        const resp = await getConversation(conversationId, await getToken({ template: "Standard" }));
        const parsedResp = await resp.json();
        setAnswers(parsedResp["messages"]);
        // @ts-expect-error - parsedResp is not null
        parsedResp["messages"].forEach(message => {
            message[1].choices[0].message.content = message[1].choices[0].message.content.replace(
                /<\[(\d+)\]>/g,
                (_: string, p1: string) => `<Quote index="${p1}" > </Quote>`
            );
            const quoteResponse: Quote[] = JSON.parse(message[1].choices[0].context.quotes);
            setQuotes([...quotes, ...quoteResponse]);
        });
        lastQuestionRef.current = parsedResp["messages"][parsedResp["messages"].length - 1][0];
    };

    const handleClose = (): void => {
        navigate(-1);
    };

    if (!pdfUrl || !documentId) {
        navigate("/");
        return null;
    }

    return (
        <PDFChatView
            pdfUrl={pdfUrl}
            documentId={documentId}
            documentName={documentName}
            answers={answers}
            handleSendQuestion={handleSendQuestion}
            isLoading={isLoading}
            onClose={handleClose}
            error={error}
            quotes={quotes}
            lastQuestionRef={lastQuestionRef}
        />
    );
};

export default PDFChatViewWrapper;
