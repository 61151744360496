import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/joy";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SummaryViewer } from "../SummaryViewer/SummaryViewer";
import { ViewOriginalButton } from "../ViewOriginalButton/ViewOriginalButton";
import { ChatPDFButton } from "../ChatPDFButton/ChatPDFButton";
import { Document } from "../../api";

interface OptionsMenuProps {
    docId: string;
    docUrl: string;
    docName: string;
    summary1?: string;
    pdf_url?: string;
    isActive: boolean;
    onMenuToggle: (isOpen: boolean) => void;
}

export const OptionsMenu: React.FC<OptionsMenuProps> = ({ docId, docUrl, docName, summary1, pdf_url, isActive, onMenuToggle }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const newState = !isActive;
        if (newState) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
        onMenuToggle(newState);
    };

    const handleClose = () => {
        setAnchorEl(null);
        onMenuToggle(false);
    };

    const document: Partial<Document> = {
        doc_id: docId,
        name: docName,
        url: docUrl,
        pdf_url: pdf_url,
        summary1: summary1
    };

    const menuItemStyles = {
        width: "100%",
        p: 0,
        "& button": {
            width: "100%",
            justifyContent: "flex-start",
            color: "primary.main",
            px: 2,
            py: 1,
            "&:hover": {
                backgroundColor: "action.hover",
                borderColor: "transparent"
            }
        }
    };

    return (
        <>
            <IconButton size="sm" variant="plain" color="neutral" onClick={handleClick} sx={{ alignSelf: "center" }}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={isActive}
                onClose={handleClose}
                placement="bottom-end"
                sx={{
                    minWidth: "200px",
                    "& .MuiMenuItem-root": {
                        p: 0
                    }
                }}
            >
                {summary1 && (
                    <MenuItem sx={menuItemStyles}>
                        <SummaryViewer document={document} mobileView={false} variantOverride="plain" isMenuButton={true} />
                    </MenuItem>
                )}
                <MenuItem sx={menuItemStyles}>
                    <ChatPDFButton document={document} variantOverride="plain" />
                </MenuItem>
                <MenuItem sx={menuItemStyles}>
                    <ViewOriginalButton document={document} onComplete={handleClose} isMenuButton={false} variantOverride="plain" />
                </MenuItem>
            </Menu>
        </>
    );
};
