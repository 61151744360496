import { Typography } from "@mui/joy";
import styles from "./SupportingContent.module.css";
import { Box } from "@mui/joy";
import { isPDF } from "../../util/utils";
import { ChatPDFButton } from "../ChatPDFButton/ChatPDFButton";
import { FrequentlyCitedLabel } from "../FrequentlyCitedLabel";
import { Document } from "../../api";
import { ViewOriginalButton } from "../ViewOriginalButton/ViewOriginalButton";
import { SummaryViewer } from "../SummaryViewer";

interface Props {
    supportingContent: string[];
    documents: Document[];
}

function removeDuplicates<T>(arr: T[], key: keyof T): T[] {
    const seen = new Set();

    return arr.filter((item: T) => {
        const value = item[key];
        if (seen.has(value)) {
            return false;
        }
        seen.add(value);
        return true;
    });
}

export const SupportingContent = ({ documents }: Props): JSX.Element => {
    documents = removeDuplicates(documents, "url");

    return (
        <ul className={styles.supportingContentNavList}>
            {documents.map((document, i) => {
                return (
                    <li key={i} className={styles.supportingContentItem}>
                        <Box>
                            <Typography level="title-sm">{document.name.replace("[frequently_cited]", "")}</Typography>
                            <Typography level="body-sm">
                                {document.category ? document.category?.split(" ")[0] : null} {document.before ? "| Judgement of " + document.before : null}
                            </Typography>
                            {document.most_cited && <FrequentlyCitedLabel />}
                        </Box>
                        <Typography level="body-sm" variant="soft">
                            " {document.extract} "
                        </Typography>

                        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                            <ViewOriginalButton document={document} variantOverride="plain" />
                            {isPDF(document.pdf_url || document.url) && <ChatPDFButton document={document} />}
                            {document.summary1 && <SummaryViewer document={document} />}
                        </Box>
                    </li>
                );
            })}
        </ul>
    );
};
