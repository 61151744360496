import React, { useState } from "react";
import { Modal, ModalClose, Sheet, Typography, Button, Textarea } from "@mui/joy";
import styles from "./FeedbackModal.module.css";

interface FeedbackModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (feedback: string) => void;
}

const FEEDBACK_CHAR_LIMIT = 1000; // Define character limit

export const FeedbackModal: React.FC<FeedbackModalProps> = ({ open, onClose, onSubmit }) => {
    const [feedback, setFeedback] = useState("");

    const handleSubmit = () => {
        onSubmit(feedback);
        setFeedback("");
        onClose();
    };

    const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        if (newValue.length <= FEEDBACK_CHAR_LIMIT) {
            setFeedback(newValue);
        }
    };

    return (
        <Modal aria-labelledby="feedback-modal-title" open={open} onClose={onClose} className={styles.modal}>
            <Sheet variant="outlined" className={styles.modalContent}>
                <ModalClose />
                <Typography id="feedback-modal-title" level="h4" className={styles.modalTitle}>
                    How can we do better?
                </Typography>
                <Textarea
                    placeholder="Please provide your feedback..."
                    value={feedback}
                    onChange={handleFeedbackChange}
                    minRows={3}
                    className={styles.textarea}
                    maxRows={6}
                />
                <Typography level="body-sm" className={styles.charCount}>
                    {feedback.length}/{FEEDBACK_CHAR_LIMIT} characters
                </Typography>
                <Button onClick={handleSubmit} className={styles.submitButton}>
                    Submit Feedback
                </Button>
            </Sheet>
        </Modal>
    );
};
