import { Modal, Button, Sheet, ModalClose, ModalDialog, Typography, Divider, DialogTitle, Box } from "@mui/joy";
import React, { useState, useEffect } from "react";
import NotesIcon from "@mui/icons-material/Notes";
import DownloadIcon from "@mui/icons-material/Download";
import { recordEvent, isMobile } from "../../util/utils";
import { JudgementCaseNote, LegislationSummary } from "../../api";
import { useDocumentView } from "../useDocumentView/useDocumentView";
import { Document } from "../../api/models";
import { Row } from "../ResultsTable";

interface SummaryViewerProps {
    document: Document | Row | Partial<Document>;
    mobileView?: boolean;
    variantOverride?: "plain" | "soft" | "outlined";
    isMenuButton?: boolean;
}

// Type guard to check if the summary is a JudgementCaseNote
const isJudgementCaseNote = (summary: JudgementCaseNote | LegislationSummary): summary is JudgementCaseNote =>
    (summary as JudgementCaseNote).case_title_citation !== undefined;

export const SummaryViewer: React.FC<SummaryViewerProps> = ({ document, mobileView, variantOverride, isMenuButton = false }) => {
    const [open, setOpen] = useState(false);
    const [isOnMobile, setIsOnMobile] = useState(isMobile());
    const { handleDocumentView } = useDocumentView();

    // Add debug logs
    console.log("SummaryViewer document:", document);
    console.log("SummaryViewer summary1:", document?.summary1);

    // Early return if no document or no valid summary
    if (!document || !document.summary1) return null;

    let summary: JudgementCaseNote | LegislationSummary;
    try {
        summary = JSON.parse(document.summary1);
    } catch {
        return null;
    }

    useEffect(() => {
        const handleResize = () => {
            setIsOnMobile(isMobile());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleOpen = async () => {
        recordEvent(
            "summary_viewed",
            isJudgementCaseNote(summary)
                ? { case_title_citation: summary.case_title_citation, court: summary.court }
                : { title_commencement: summary.title_commencement }
        );

        await handleDocumentView(document.doc_id as string, document);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleDownload = () => {
        const content = isJudgementCaseNote(summary)
            ? `
${summary.case_title_citation}

Date of Judgment: ${summary.judgement_date}
Court: ${summary.court}
Judge: ${summary.judge}
Plaintiff: ${summary.parties?.plaintiff}
Defendant: ${summary.parties?.defendant}

ISSUE
${summary.issue}

LAW
${summary.law}

ANALYSIS
${summary.analysis}

SIGNIFICANCE
${summary.significance}

CONCLUSION
${summary.conclusion}
            `.trim()
            : `
${summary.title_commencement}

PURPOSE AND OBJECTIVES
${summary.purpose_objectives}

KEY PROVISIONS
${summary.key_provisions}

SCOPE AND APPLICATION
${summary.scope_application}

RIGHTS AND OBLIGATIONS
${summary.rights_obligations}

ENFORCEMENT AND PENALTIES
${summary.enforcement_penalties}

EXEMPTIONS AND DEFENSES
${summary.exemptions_defenses}

RELATED LEGISLATION AND CASE LAW
${summary.related_legislation_case_law}

IMPACT AND IMPLICATIONS
${summary.impact_implications}
            `.trim();

        const filename = isJudgementCaseNote(summary)
            ? `${summary.case_title_citation.replace(/[^a-z0-9]/gi, "_").toLowerCase()}_case_note.txt`
            : `${summary.title_commencement.replace(/[^a-z0-9]/gi, "_").toLowerCase()}_legislation_summary.txt`;

        const blob = new Blob([content], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const link = window.document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        window.document.body.appendChild(link);
        link.click();
        window.document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        recordEvent(
            "summary_downloaded",
            isJudgementCaseNote(summary)
                ? { case_title_citation: summary.case_title_citation, court: summary.court }
                : { title_commencement: summary.title_commencement }
        );
    };

    if (!document) return null;

    return (
        <div style={{ width: isMenuButton ? "100%" : "auto" }}>
            <Button
                startDecorator={mobileView ? undefined : <NotesIcon />}
                color="primary"
                variant={variantOverride || "plain"}
                size="md"
                onClick={handleOpen}
                sx={{
                    width: isMenuButton ? "100%" : "auto",
                    justifyContent: isMenuButton ? "flex-start" : "center"
                }}
            >
                {mobileView ? <NotesIcon /> : "Summary"}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <ModalDialog
                    sx={{
                        width: isOnMobile ? "100%" : "80%",
                        maxWidth: isOnMobile ? "100%" : "1000px",
                        margin: "0 auto",
                        height: isOnMobile ? "100%" : undefined,
                        maxHeight: isOnMobile ? "100%" : "90vh",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: isOnMobile ? 0 : undefined
                    }}
                >
                    <ModalClose />
                    <DialogTitle>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography level="body-xs" sx={{ marginRight: 2 }}>
                                These notes were generated by AI.
                            </Typography>
                            <Button
                                startDecorator={<DownloadIcon />}
                                variant="outlined"
                                color="neutral"
                                size="sm"
                                onClick={handleDownload}
                                sx={{
                                    marginLeft: "auto",
                                    minWidth: isOnMobile ? "unset" : undefined,
                                    paddingLeft: isOnMobile ? 1 : undefined,
                                    paddingRight: isOnMobile ? 1 : undefined
                                }}
                            >
                                {!isOnMobile && "Download"}
                            </Button>
                        </Box>
                    </DialogTitle>
                    <Sheet
                        sx={{
                            flexGrow: 1,
                            overflowY: "auto",
                            padding: isOnMobile ? "15px" : "30px",
                            gap: 1.5,
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        {isJudgementCaseNote(summary) ? (
                            <>
                                <Typography level="h3">{summary.case_title_citation}</Typography>
                                <Typography level="body-sm">
                                    <b>Date of Judgment:</b> {summary.judgement_date}
                                </Typography>
                                <Typography level="body-sm">
                                    <b>Court:</b> {summary.court}
                                </Typography>
                                <Typography level="body-sm">
                                    <b>Judge:</b> {summary.judge}
                                </Typography>
                                <Typography level="body-sm">
                                    <b>Plaintiff:</b> {summary.parties?.plaintiff}
                                </Typography>
                                <Typography level="body-sm">
                                    <b>Defendant:</b> {summary.parties?.defendant}
                                </Typography>
                                <Divider />
                                <Typography level="h4">Issue</Typography>
                                <Typography level="body-md">{summary.issue}</Typography>
                                <Typography level="h4">Law</Typography>
                                <Typography level="body-md">{summary.law}</Typography>
                                <Typography level="h4">Analysis</Typography>
                                <Typography level="body-md">{summary.analysis}</Typography>
                                <Typography level="h4">Significance</Typography>
                                <Typography level="body-md">{summary.significance}</Typography>
                                <Typography level="h4">Conclusion</Typography>
                                <Typography level="body-md">{summary.conclusion}</Typography>
                            </>
                        ) : (
                            <>
                                <Typography level="h3">{summary.title_commencement}</Typography>
                                <Typography level="h4">Purpose and Objectives</Typography>
                                <Typography level="body-md">{summary.purpose_objectives}</Typography>
                                <Typography level="h4">Key Provisions</Typography>
                                <Typography level="body-md">{summary.key_provisions}</Typography>
                                <Typography level="h4">Scope and Application</Typography>
                                <Typography level="body-md">{summary.scope_application}</Typography>
                                <Typography level="h4">Rights and Obligations</Typography>
                                <Typography level="body-md">{summary.rights_obligations}</Typography>
                                <Typography level="h4">Enforcement and Penalties</Typography>
                                <Typography level="body-md">{summary.enforcement_penalties}</Typography>
                                <Typography level="h4">Exemptions and Defenses</Typography>
                                <Typography level="body-md">{summary.exemptions_defenses}</Typography>
                                <Typography level="h4">Related Legislation and Case Law</Typography>
                                <Typography level="body-md">{summary.related_legislation_case_law}</Typography>
                                <Typography level="h4">Impact and Implications</Typography>
                                <Typography level="body-md">{summary.impact_implications}</Typography>
                            </>
                        )}
                    </Sheet>
                </ModalDialog>
            </Modal>
        </div>
    );
};
