import React, { useState, useEffect } from "react";
import { Box, Typography, ButtonGroup, Button, IconButton, List, ListItem, ListSubheader, Modal } from "@mui/joy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FavoriteButton } from "../FavoriteButton/FavoriteButton";
import { useNavigate } from "react-router-dom";
import styles from "./HistoryMenu.module.css";
import { Conversation, ConversationMap } from "../Sidebar";

interface MobileHistoryMenuProps {
    open: boolean;
    onClose: () => void;
    groupedConversations: ConversationMap;
    onSidebarClose?: () => void;
    onFavoriteChange?: (convoId: string, isFavorite: boolean) => void;
}

export const MobileHistoryMenu: React.FC<MobileHistoryMenuProps> = ({ open, onClose, groupedConversations, onSidebarClose, onFavoriteChange }) => {
    const navigate = useNavigate();
    const [viewMode, setViewMode] = React.useState<"recent" | "starred">("recent");
    const [localConversations, setLocalConversations] = useState(groupedConversations);

    useEffect(() => {
        setLocalConversations(groupedConversations);
    }, [groupedConversations]);

    const handleFavoriteChange = (convoId: string, isFavorite: boolean) => {
        const updatedConversations = { ...localConversations };
        Object.keys(updatedConversations).forEach(date => {
            updatedConversations[date] = updatedConversations[date].map(convo =>
                convo.id === convoId
                    ? {
                          ...convo,
                          favorite: isFavorite
                      }
                    : convo
            );
        });
        setLocalConversations(updatedConversations);

        onFavoriteChange?.(convoId, isFavorite);
    };

    const filteredConversations = React.useMemo(() => {
        if (viewMode === "starred") {
            return Object.keys(localConversations).reduce(
                (acc, date) => {
                    const starredConvos = localConversations[date].filter(convo => convo.favorite);
                    if (starredConvos.length > 0) {
                        acc[date] = starredConvos;
                    }
                    return acc;
                },
                {} as Record<string, Conversation[]>
            );
        }
        return localConversations;
    }, [localConversations, viewMode]);

    const handleConversationClick = (convo: Conversation) => {
        navigate(convo.type === "pdf" ? `/pdf-chat/?conversationId=${convo.id}` : `/?conversationId=${convo.id}`, {
            state: { pdfUrl: convo.url, documentId: convo.doc_id }
        });
        onClose();
        onSidebarClose?.();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Box
                data-mobile-history-menu="true"
                className={styles.historyMenu}
                sx={{
                    position: "fixed",
                    left: 0,
                    top: 0,
                    height: "100vh",
                    width: "100%",
                    backgroundColor: "background.surface",
                    zIndex: 1200,
                    overflowY: "auto"
                }}
            >
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "background.surface",
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <IconButton onClick={onClose} variant="plain">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography level="title-lg">History</Typography>
                    </Box>
                    <ButtonGroup
                        variant="soft"
                        size="sm"
                        sx={{
                            alignSelf: "center"
                        }}
                    >
                        <Button
                            onClick={() => setViewMode("recent")}
                            variant={viewMode === "recent" ? "solid" : "soft"}
                            color={viewMode === "recent" ? "primary" : "neutral"}
                        >
                            Recent
                        </Button>
                        <Button
                            onClick={() => setViewMode("starred")}
                            variant={viewMode === "starred" ? "solid" : "soft"}
                            color={viewMode === "starred" ? "primary" : "neutral"}
                        >
                            Starred
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box
                    className={`${styles.chatHistory} ${styles.chatHistoryScrollbar}`}
                    sx={{
                        height: "calc(100% - 64px)",
                        overflowY: "auto",
                        overflowX: "hidden",
                        p: 2
                    }}
                >
                    <List>
                        {Object.keys(filteredConversations).map((date: string) => (
                            <ListItem nested key={date}>
                                <ListSubheader>{date}</ListSubheader>
                                <List>
                                    {filteredConversations[date].map((convo: Conversation) => (
                                        <ListItem key={convo.id} sx={{ mt: 0.25 }}>
                                            {convo.messages[0]?.question && (
                                                <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                                                    <FavoriteButton
                                                        id={convo.id}
                                                        type="conversation"
                                                        initialFavoriteState={convo.favorite || false}
                                                        size="sm"
                                                        className={styles.historyFavoriteButton}
                                                        onFavoriteChange={isFavorite => handleFavoriteChange(convo.id, isFavorite)}
                                                    />
                                                    <Box
                                                        onClick={() => handleConversationClick(convo)}
                                                        sx={{
                                                            cursor: "pointer",
                                                            p: 1,
                                                            borderRadius: 1,
                                                            width: "100%",
                                                            "&:hover": {
                                                                backgroundColor: "var(--joy-palette-neutral-100, #f0f4f8)"
                                                            }
                                                        }}
                                                    >
                                                        <Typography noWrap={true} level="body-sm">
                                                            {convo.messages[0]?.question}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </ListItem>
                        ))}
                        {viewMode === "starred" && Object.keys(filteredConversations).length === 0 && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100px"
                                }}
                            >
                                <Typography level="body-sm" color="neutral">
                                    No starred conversations yet
                                </Typography>
                            </Box>
                        )}
                    </List>
                </Box>
            </Box>
        </Modal>
    );
};
