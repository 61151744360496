import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, List, ListItem, ListItemButton, ListItemContent, Sheet, Typography, Chip } from "@mui/joy";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import { listConversations } from "../../api";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./Sidebar.module.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/logo_b.png";
import { useAuth } from "@clerk/clerk-react";
import { UserMenu } from "../UserMenu";
import { format, isToday, isYesterday } from "date-fns";
import { SubscriptionDetail } from "../SubscriptionDetail";
import { Add } from "@mui/icons-material";
import RestoreIcon from "@mui/icons-material/Restore";
import { HistoryMenu } from "../HistoryMenu/HistoryMenu";
import { MobileHistoryMenu } from "../HistoryMenu/MobileHistoryMenu";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import { DocMenu } from "../DocMenu/DocMenu";

interface Message {
    answer: string;
    conversation: Conversation;
    created: string;
    id: number;
    question: string;
    updated: string;
    user: string;
}

const isMobile = () => window.innerWidth < 900; // Using MUI's default md breakpoint

interface SidebarProps {
    onClose?: () => void;
    sidebarOpen: boolean;
}

export interface ConversationMap {
    [key: string]: Conversation[];
}

export interface Conversation {
    id: string;
    created: string;
    type: "chat" | "pdf";
    messages: Message[];
    url?: string;
    doc_id?: string;
    favorite?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ onClose, sidebarOpen }) => {
    const { isSignedIn, getToken } = useAuth();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const boxRef = useRef<HTMLElement | null>(null);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [historyMenuOpen, setHistoryMenuOpen] = useState(false);
    const [docMenuOpen, setDocMenuOpen] = useState(false);
    const [mobileHistoryMenuOpen, setMobileHistoryMenuOpen] = useState(false);

    const getConversations = async (page: number) => {
        if (!hasMoreItems) {
            return;
        }
        setLoading(true);
        const resp = await listConversations(await getToken({ template: "Standard" }), page);
        const data = await resp.json();

        if (data.length === 0) {
            setHasMoreItems(false);
            setLoading(false);
            return;
        }

        if (page === 1) {
            setConversations(data);
        } else {
            const newConversations = data.filter(
                (newConvo: Conversation) => !conversations.some((existingConvo: Conversation) => existingConvo.id === newConvo.id)
            );
            setConversations(prevConversations => [...prevConversations, ...newConversations]);
        }
        setLoading(false);
    };

    const setNewChat = () => {
        searchParams.delete("conversationId");
        setSearchParams(searchParams);
        getConversations(1);
        navigate("/");
        if (onClose) onClose();
    };

    useEffect(() => {
        if (isSignedIn) {
            getConversations(page);
        }
    }, [isSignedIn, page]);

    useEffect(() => {
        const handleScroll = () => {
            const box = boxRef.current;
            if (box && box.scrollHeight - box.scrollTop === box.clientHeight && !loading && hasMoreItems) {
                setPage(prevPage => prevPage + 1);
            }
        };
        const box = boxRef.current;
        if (box) {
            box.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (box) {
                box.removeEventListener("scroll", handleScroll);
            }
        };
    }, [loading, hasMoreItems]);

    // Add new useEffect for mobile view
    useEffect(() => {
        if (isMobile() && sidebarOpen) {
            setPage(1);
            setHasMoreItems(true);
            setConversations([]); // Clear existing conversations
            getConversations(1); // Fetch fresh data
        }
    }, [sidebarOpen]);

    const groupedConversations = conversations
        .sort((a, b) => {
            if (a.created && b.created) {
                return new Date(b.created).getTime() - new Date(a.created).getTime();
            } else {
                return 0;
            }
        })
        .reduce((groups: { [key: string]: Conversation[] }, convo) => {
            if (convo.created) {
                let dateObject = new Date(convo.created);
                dateObject = new Date(dateObject.toLocaleString("en-US", { timeZone: "Australia/Sydney" }));
                let formattedDate;
                if (isToday(dateObject)) {
                    formattedDate = "Today";
                } else if (isYesterday(dateObject)) {
                    formattedDate = "Yesterday";
                } else {
                    formattedDate = format(dateObject, "MMMM dd, yyyy");
                }
                if (!groups[formattedDate]) {
                    groups[formattedDate] = [];
                }
                groups[formattedDate].push(convo);
            }
            return groups;
        }, {} as ConversationMap);

    const handleClickAway = () => {
        if (isMobile() && onClose) {
            onClose();
        }
    };

    // Add event listener for new conversations
    useEffect(() => {
        const handleNewConversation = (event: CustomEvent) => {
            const newConvo = event.detail;

            setConversations(prevConversations => {
                const updatedConversations = [newConvo, ...prevConversations];
                return updatedConversations;
            });
        };

        window.addEventListener("newConversation", handleNewConversation as EventListener);
        return () => {
            window.removeEventListener("newConversation", handleNewConversation as EventListener);
        };
    }, []);

    const handleHistoryMenuClick = () => {
        setHistoryMenuOpen(!historyMenuOpen);
        setDocMenuOpen(false); // Close doc menu if open
    };

    const handleDocMenuClick = () => {
        setDocMenuOpen(!docMenuOpen);
        setHistoryMenuOpen(false); // Close history menu if open
        setMobileHistoryMenuOpen(false); // Close mobile history menu if open
    };

    const handleMobileHistoryClick = () => {
        setMobileHistoryMenuOpen(true);
        setDocMenuOpen(false); // Close doc menu if open
    };

    const handleFavoriteChange = (convoId: string, isFavorite: boolean) => {
        setConversations(prevConversations =>
            prevConversations.map(convo =>
                convo.id === convoId
                    ? {
                          ...convo,
                          favorite: isFavorite
                      }
                    : convo
            )
        );
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Sheet
                sx={{
                    position: { xs: "fixed", md: "sticky" },
                    transform: {
                        md: "none"
                    },
                    transition: "transform 0.4s, width 0.4s",
                    height: "100dvh",
                    maxHeight: "100vh",
                    width: "var(--Sidebar-width)",
                    top: 0,
                    p: 2,
                    zIndex: 999,
                    flexShrink: 0,
                    boxSizing: "border-box",
                    display: sidebarOpen ? "flex" : "none",
                    flexDirection: "column",
                    gap: { xs: 1, md: 3 },
                    borderRight: "1px solid",
                    borderColor: "divider",
                    overflow: "hidden",
                    maxWidth: "10dvw",
                    minWidth: "220px"
                }}
            >
                <Box sx={{ display: { xs: "none", md: "block" } }} className={styles.logoArea}>
                    <Link to={"/"} onClick={() => setNewChat()} className={styles.headerTitleContainer}>
                        <img className={styles.headerLogo} src={logo} />
                    </Link>
                </Box>
                <List sx={{ overflow: "hidden" }} size="sm">
                    <ListItem>
                        <ListItemButton className={styles.largeListItemButton} onClick={() => setNewChat()}>
                            <QuestionAnswerRoundedIcon />
                            <ListItemContent>
                                <Typography level="title-md">New chat</Typography>
                            </ListItemContent>
                            <Add />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton className={styles.largeListItemButton} onClick={() => navigate("/casenotes")}>
                            <SearchIcon />
                            <ListItemContent>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Typography level="title-md">Search</Typography>
                                    <Chip
                                        size="sm"
                                        variant="soft"
                                        sx={{
                                            backgroundColor: "#1972aa",
                                            color: "white",
                                            fontSize: "0.65rem",
                                            height: "20px",
                                            transform: "translateY(-8px)",
                                            padding: "0 6px",
                                            minHeight: "16px"
                                        }}
                                    >
                                        Free
                                    </Chip>
                                </Box>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <ListItem>
                            <ListItemButton
                                className={styles.largeListItemButton}
                                onClick={handleHistoryMenuClick}
                                selected={historyMenuOpen}
                                sx={{
                                    "&.Mui-selected": {
                                        backgroundColor: "var(--joy-palette-neutral-200, #e3e8ef)",
                                        "&:hover": {
                                            backgroundColor: "var(--joy-palette-neutral-300, #d3dae3)"
                                        }
                                    }
                                }}
                            >
                                <RestoreIcon />
                                <ListItemContent>
                                    <Typography level="title-md">History</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <HistoryMenu
                            open={historyMenuOpen}
                            onClose={() => setHistoryMenuOpen(false)}
                            groupedConversations={groupedConversations}
                            onFavoriteChange={handleFavoriteChange}
                        />
                    </Box>

                    <Box sx={{ display: { xs: "block", md: "none" } }}>
                        <ListItem>
                            <ListItemButton className={styles.largeListItemButton} onClick={handleMobileHistoryClick}>
                                <RestoreIcon />
                                <ListItemContent>
                                    <Typography level="title-md">History</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <MobileHistoryMenu
                            open={mobileHistoryMenuOpen}
                            onClose={() => setMobileHistoryMenuOpen(false)}
                            groupedConversations={groupedConversations}
                            onSidebarClose={onClose}
                            onFavoriteChange={handleFavoriteChange}
                        />
                    </Box>

                    <ListItem>
                        <ListItemButton
                            className={styles.largeListItemButton}
                            onClick={handleDocMenuClick}
                            selected={docMenuOpen}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor: "var(--joy-palette-neutral-200, #e3e8ef)",
                                    "&:hover": {
                                        backgroundColor: "var(--joy-palette-neutral-300, #d3dae3)"
                                    }
                                }
                            }}
                        >
                            <DescriptionIcon />
                            <ListItemContent>
                                <Typography level="title-md">Documents</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 0, gap: { xs: 1, md: 3 } }}>
                    {isSignedIn && <SubscriptionDetail />}
                    <UserMenu />
                </Box>

                <DocMenu
                    open={docMenuOpen}
                    onClose={() => {
                        setDocMenuOpen(false);
                        if (isMobile() && onClose) {
                            onClose();
                        }
                    }}
                />
            </Sheet>
        </ClickAwayListener>
    );
};
