import React from "react";
import { Modal, Button, Stack, Typography } from "@mui/joy";
import styles from "./SourceActionPopup.module.css";
import { SummaryViewer } from "../SummaryViewer/SummaryViewer";
import { Document } from "../../api";
import { ChatPDFButton } from "../ChatPDFButton/ChatPDFButton";
import { ViewOriginalButton } from "../ViewOriginalButton/ViewOriginalButton";

interface SourceActionPopupProps {
    open: boolean;
    onClose: () => void;
    resultDocument?: Document;
}

export const SourceActionPopup: React.FC<SourceActionPopupProps> = ({ open, onClose, resultDocument }) => {
    if (!resultDocument) {
        return null;
    }

    const canChatWithPDF = resultDocument?.url?.toLowerCase?.()?.endsWith(".pdf") ?? false;

    console.log("SourceActionPopup resultDocument:", resultDocument);
    console.log("SourceActionPopup summary1:", resultDocument.summary1);

    const handleDownload = async () => {
        if (!resultDocument.url) {
            return;
        }

        try {
            const response = await fetch(resultDocument.url, {
                headers: {
                    "Content-Type": "application/pdf",
                    "Content-Disposition": 'attachment; filename="' + (resultDocument.name || "document.pdf") + '"'
                }
            });

            const blob = await response.blob();

            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", resultDocument.name || "document.pdf");

            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);

            onClose();
        } catch (error) {
            console.error("Error downloading file:", error);
            window.open(resultDocument.url, "_blank");
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={onClose} className={styles.modal}>
            <Stack spacing={1} className={styles.buttonContainer}>
                <Typography level="title-md" className={styles.sourceTitle} fontWeight="bold">
                    {resultDocument.name || "Untitled Document"}
                </Typography>

                {canChatWithPDF && resultDocument && (
                    <div className={styles.actionButton}>
                        <ChatPDFButton variantOverride={"soft"} document={resultDocument} />
                    </div>
                )}

                {resultDocument.summary1 && (
                    <div className={styles.actionButton}>
                        <SummaryViewer document={resultDocument} variantOverride="soft" isMenuButton={true} />
                    </div>
                )}

                {resultDocument.url && <ViewOriginalButton document={resultDocument} variantOverride="soft" onComplete={onClose} sx={{ width: "100%" }} />}

                {canChatWithPDF && resultDocument.url && (
                    <Button variant="soft" color="primary" onClick={handleDownload} className={styles.actionButton}>
                        Download PDF
                    </Button>
                )}
            </Stack>
        </Modal>
    );
};
