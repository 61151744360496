import React, { useState } from "react";
import { Box } from "@mui/joy";
import { IconButton as JoyIconButton } from "@mui/joy";
import ThumbUpIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpIconFilled from "@mui/icons-material/ThumbUp";
import ThumbDownIconFilled from "@mui/icons-material/ThumbDown";
import { FeedbackModal } from "../FeedbackModal/FeedbackModal";
import styles from "./FeedbackButtons.module.css";
import { submitFeedback } from "../../api";
import { useAuth } from "@clerk/clerk-react";
import { recordEvent } from "../../util/utils";

const DARK_BLUE = "#123bb6";

interface FeedbackButtonsProps {
    extraButton?: React.ReactNode;
    messageId?: number;
}

export const FeedbackButtons: React.FC<FeedbackButtonsProps> = ({ extraButton, messageId }) => {
    const { getToken } = useAuth();
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [feedback, setFeedback] = useState<"up" | "down" | null>(null);

    const handleFeedback = async (isPositive: boolean) => {
        try {
            recordEvent("Feedback", {
                feedback: isPositive ? "up" : "down"
            });

            if (!messageId) return;

            if ((isPositive && feedback === "up") || (!isPositive && feedback === "down")) {
                setFeedback(null);
                return;
            }

            const token = await getToken({ template: "Standard" });
            const newFeedback = isPositive ? "up" : "down";
            setFeedback(newFeedback);

            if (isPositive) {
                await submitFeedback(messageId, true, "", token);
            } else {
                setShowFeedbackModal(true);
            }
        } catch (error) {
            console.error("Error submitting feedback:", error);
        }
    };

    const handleFeedbackModalSubmit = async (feedbackText: string) => {
        try {
            if (!messageId) return;
            const token = await getToken({ template: "Standard" });
            await submitFeedback(messageId, false, feedbackText, token);
            setShowFeedbackModal(false);
        } catch (error) {
            console.error("Error submitting feedback:", error);
        }
    };

    return (
        <>
            <Box className={styles.feedbackContainer}>
                <Box className={styles.leftContent}>{extraButton}</Box>
                <Box className={styles.rightContent}>
                    <JoyIconButton
                        onClick={() => handleFeedback(true)}
                        size="sm"
                        variant="plain"
                        color="neutral"
                        sx={{
                            "&:hover": { color: DARK_BLUE }
                        }}
                    >
                        {feedback === "up" ? <ThumbUpIconFilled sx={{ color: DARK_BLUE }} /> : <ThumbUpIcon />}
                    </JoyIconButton>
                    <JoyIconButton
                        onClick={() => handleFeedback(false)}
                        size="sm"
                        variant="plain"
                        color="neutral"
                        sx={{
                            "&:hover": { color: DARK_BLUE }
                        }}
                    >
                        {feedback === "down" ? <ThumbDownIconFilled sx={{ color: DARK_BLUE }} /> : <ThumbDownIcon />}
                    </JoyIconButton>
                </Box>
            </Box>

            <FeedbackModal open={showFeedbackModal} onClose={() => setShowFeedbackModal(false)} onSubmit={handleFeedbackModalSubmit} />
        </>
    );
};
