// React Component that displays dropdown select filters for every item in FILTERS array
import styles from "../../pages/chat/Chat.module.css";
import { Filter, getFiltersByCountry } from "../../util/constants";
import { Select, Option, Typography, Box, Autocomplete, AutocompleteOption, ListItemContent } from "@mui/joy";
import { FilterRequest } from "../../api";
import IconButton from "@mui/joy/IconButton";
import { CloseRounded } from "@mui/icons-material";
import { QuestionInput } from "../QuestionInput";
import { useContext, useEffect } from "react";
import { CountryContext } from "../../contexts/CountryContext";
import { FilterButtons } from "./FilterButtons";

const capitaliseFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};
const getFilteredOptions = (filter: Filter, filters: FilterRequest) => {
    const filterBy = filter.filterBy;
    if (filterBy) {
        const filterByValue = filters[filterBy];
        if (filterByValue?.length === 0 || !filterByValue) return filter.options;
        return filter.options.filter(option => filterByValue?.includes(option.group as string));
    }
    return filter.options;
};

const SingleSelect = ({
    filter,
    onUpdateFilters,
    disabled,
    filters
}: {
    filter: Filter;
    onUpdateFilters: (fieldName: string, Value: string[]) => void;
    disabled: boolean;
    filters: FilterRequest;
}) => {
    const options = getFilteredOptions(filter, filters);
    const currentFilter = filters[filter.fieldName];

    return (
        <>
            <Select
                disabled={disabled}
                value={filters[filter.fieldName] || []}
                multiple
                placeholder="All"
                onChange={(event, newValue) => onUpdateFilters(filter.fieldName, newValue)}
                {...(currentFilter &&
                    currentFilter?.length > 0 && {
                        // display the button and remove select indicator
                        // when user has selected a value
                        endDecorator: (
                            <IconButton
                                size="sm"
                                variant="plain"
                                color="neutral"
                                onMouseDown={event => {
                                    // don't open the popup when clicking on this button
                                    event.stopPropagation();
                                }}
                                onClick={() => {
                                    onUpdateFilters(filter.fieldName, []);
                                }}
                            >
                                <CloseRounded />
                            </IconButton>
                        ),
                        indicator: null
                    })}
            >
                {options.map((option, _) => (
                    <Option value={option.value}>{capitaliseFirstLetter(option.displayValue)}</Option>
                ))}
            </Select>
        </>
    );
};

const SearchSelect = ({
    filter,
    onUpdateFilters,
    disabled,
    filters
}: {
    filter: Filter;
    onUpdateFilters: (key: string, value: string[]) => void;
    disabled: boolean;
    filters: FilterRequest;
}) => {
    const options = getFilteredOptions(filter, filters);

    return (
        <>
            <Autocomplete
                disabled={disabled}
                onChange={(event, newValue) =>
                    onUpdateFilters(
                        filter.fieldName,
                        newValue.map(value => value.value)
                    )
                }
                options={options}
                getOptionLabel={option => option.displayValue}
                sx={{ width: "98%", overflow: "visible" }}
                disableListWrap
                multiple
                placeholder={(filters[filter.fieldName] || []).length > 0 ? "" : "All"}
                renderOption={(props, option) => (
                    <AutocompleteOption sx={{ width: "320px" }} {...props}>
                        {" "}
                        <ListItemContent sx={{ fontSize: "sm" }}>{option.displayValue}</ListItemContent>
                    </AutocompleteOption>
                )}
                groupBy={option => option.group || ""}
            />
        </>
    );
};

export const Filters = ({
    filters,
    setFilters,
    includeFilters,
    includeSearch,
    setSearch,
    searchPlaceholder = "Search for Anything",
    layout = "default",
    className,
    viewMode,
    initialQuestion = "",
    onViewModeChange,
    searchTerm
}: {
    filters: FilterRequest;
    setFilters: (arg: FilterRequest) => void;
    includeFilters: string[];
    includeSearch?: boolean;
    setSearch?: (question: string) => void;
    searchPlaceholder?: string;
    layout?: "default" | "search-first";
    className?: string;
    viewMode?: "normal" | "simple";
    initialQuestion?: string;
    onViewModeChange?: (event: React.MouseEvent<HTMLElement>, newMode: "normal" | "simple" | null) => void;
    searchTerm?: string;
}) => {
    const { country } = useContext(CountryContext);
    const onUpdateFilters = (key: string, newValue: string[] | null) => {
        if (!newValue) {
            return;
        }
        setFilters({ ...filters, ...{ [key]: newValue } });
    };

    const checkIfDisabled = (filter: Filter) => {
        const reliesOn = filter.reliesOn;
        if (reliesOn?.value) {
            const isEnabled = filters[reliesOn.key]?.includes(reliesOn.value) || filters[reliesOn.key]?.length === 0 || !filters[reliesOn.key];
            if (isEnabled && filters[filter.fieldName] !== null) {
                onUpdateFilters(filter.fieldName, null);
            }
            return !isEnabled;
        }
        return false;
    };

    let displayFilters = includeFilters
        ? getFiltersByCountry(country.code).filter(filter => includeFilters.includes(filter.fieldName))
        : getFiltersByCountry(country.code);

    useEffect(() => {
        onUpdateFilters("country", [country.name]);
        displayFilters = includeFilters
            ? getFiltersByCountry(country.code).filter(filter => includeFilters.includes(filter.fieldName))
            : getFiltersByCountry(country.code);
    }, [country]);

    return (
        <div className={className || styles.header}>
            {layout === "search-first" && includeSearch && setSearch && (
                <div className={layout === "search-first" ? styles.feedSearch : styles.search}>
                    <QuestionInput
                        allowEmpty
                        size="lg"
                        placeholder={searchPlaceholder}
                        onSend={setSearch}
                        useSearchIcon={true}
                        initialQuestion={initialQuestion}
                    />
                </div>
            )}

            {layout === "search-first" && (
                <div className={styles.feedFiltersWrapper}>
                    <div className={styles.filterButtonsContainer}>
                        <FilterButtons filters={filters} onUpdateFilters={onUpdateFilters} viewMode={viewMode} onViewModeChange={onViewModeChange} />
                    </div>

                    <div className={styles.filtersContainer}>
                        {displayFilters.map((filter, _) => (
                            <>
                                {!checkIfDisabled(filter) && filter.fieldName !== "doc_type" && (
                                    <Box className={styles.filter}>
                                        <Typography level="body-sm">{filter.displayName}</Typography>
                                        {filter.type === "search" ? (
                                            <SearchSelect
                                                filter={filter}
                                                filters={filters}
                                                onUpdateFilters={onUpdateFilters}
                                                disabled={checkIfDisabled(filter)}
                                            />
                                        ) : (
                                            <SingleSelect
                                                filter={filter}
                                                filters={filters}
                                                onUpdateFilters={onUpdateFilters}
                                                disabled={checkIfDisabled(filter)}
                                            />
                                        )}
                                    </Box>
                                )}
                            </>
                        ))}
                    </div>
                </div>
            )}

            {layout === "default" && (
                <div className={styles.chatFiltersContainer}>
                    {displayFilters.map((filter, _) => (
                        <>
                            {!checkIfDisabled(filter) && (
                                <Box className={styles.filter}>
                                    <Typography level="body-sm">{filter.displayName}</Typography>
                                    {filter.type === "search" ? (
                                        <SearchSelect filter={filter} filters={filters} onUpdateFilters={onUpdateFilters} disabled={checkIfDisabled(filter)} />
                                    ) : (
                                        <SingleSelect filter={filter} filters={filters} onUpdateFilters={onUpdateFilters} disabled={checkIfDisabled(filter)} />
                                    )}
                                </Box>
                            )}
                        </>
                    ))}
                </div>
            )}

            {layout === "default" && includeSearch && setSearch && (
                <div className={styles.search}>
                    <QuestionInput allowEmpty size="lg" placeholder={searchPlaceholder} onSend={setSearch} initialQuestion={searchTerm} />
                </div>
            )}
        </div>
    );
};
