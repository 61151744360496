import React, { useEffect, useMemo, useState } from "react";
import { Stack, Card, Typography, Box, Chip, Divider, Skeleton, Button } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import styles from "./Feed.module.css";
import { SearchRequest, Document, searchRequest, FilterRequest } from "../../api";
import { useAuth } from "@clerk/clerk-react";
import { Filters } from "../../components/Filters";
import { Download } from "@mui/icons-material";
import { format } from "date-fns";
import { ChatPDFButton } from "../../components/ChatPDFButton/ChatPDFButton";
import { isPDF } from "../../util/utils";
import { ResultsTable } from "../../components/ResultsTable/ResultsTable";
import { SummaryViewer } from "../../components/SummaryViewer";
import { useNavigate, useSearchParams } from "react-router-dom";
import FormatQuote from "@mui/icons-material/FormatQuote";
import { ViewOriginalButton } from "../../components/ViewOriginalButton/ViewOriginalButton";

const Result = ({ row }: { row: Document }) => {
    const isMobile = useMediaQuery("(max-width:844px)");
    const navigate = useNavigate();

    const handleTitleClick = (e: React.MouseEvent) => {
        if (isPDF(row.pdf_url || row.url)) {
            e.preventDefault();
            navigate("/pdf-chat", {
                state: {
                    pdfUrl: row.pdf_url || row.url,
                    documentId: row.doc_id,
                    documentName: row.name
                }
            });
        }
    };

    return (
        <Card size={"lg"} className={styles.ResultCard}>
            <div>
                <Typography
                    level={"body-lg"}
                    component="a"
                    href={row.url}
                    onClick={handleTitleClick}
                    sx={{
                        cursor: !isMobile && isPDF(row.pdf_url || row.url) ? "pointer" : "default",
                        textDecoration: "none",
                        color: "inherit",
                        "&:hover": {
                            textDecoration: !isMobile && isPDF(row.pdf_url || row.url) ? "underline" : "none"
                        }
                    }}
                >
                    {row.name}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {(row.sort_date || row.date || row.decision_date) && (
                        <Typography level={"body-xs"}>
                            {(() => {
                                try {
                                    return format(new Date(row.sort_date || row.date || row.decision_date), "d MMMM yyyy");
                                } catch {
                                    return "";
                                }
                            })()}
                        </Typography>
                    )}
                    {(row.sort_date || row.date || row.decision_date) && row.jurisdiction && <Typography level={"body-xs"}>&nbsp;&bull;&nbsp;</Typography>}
                    {row.jurisdiction && <Typography level={"body-xs"}>{row.jurisdiction}</Typography>}
                </Box>
            </div>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px", marginY: 1 }}>
                {row.categories &&
                    row.categories.map((category, index) => (
                        <Chip key={index} size={"sm"} variant={"solid"}>
                            {category}
                        </Chip>
                    ))}
            </Box>
            <Divider />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1, marginY: 1 }}>
                {row.blurb && <Typography level={"body-sm"}>{row.blurb}</Typography>}
            </Box>
            <Box>
                {row.highlights && (
                    <Typography
                        sx={{ alignItems: "flex-start", fontStyle: "italic" }}
                        level="body-sm"
                        startDecorator={<FormatQuote />}
                        variant="soft"
                        color="neutral"
                    >
                        {row.highlights[0]}
                    </Typography>
                )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: isMobile ? 1 : 3 }}>
                {row.summary1 &&
                    (() => {
                        try {
                            JSON.parse(row.summary1);
                            return <SummaryViewer document={row} mobileView={isMobile} />;
                        } catch {
                            return null;
                        }
                    })()}
                {isPDF(row.pdf_url || row.url) && (
                    <>
                        <ChatPDFButton document={row} mobileView={isMobile} />
                        <Button
                            startDecorator={isMobile ? undefined : <Download />}
                            size={"md"}
                            variant={"plain"}
                            component="a"
                            href={row.pdf_url || row.url}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {isMobile ? <Download /> : "Download"}
                        </Button>
                    </>
                )}
                <ViewOriginalButton document={row} mobileView={isMobile} />
            </Box>
        </Card>
    );
};

const SkeletonResult = () => (
    <Card size={"lg"} className={styles.ResultCard}>
        <Skeleton animation="wave" variant="text" />
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            <Skeleton animation="wave" variant="text" width={60} />
            <Skeleton animation="wave" variant="text" width={120} />
            <Skeleton animation="wave" variant="text" width={60} />
            <Skeleton animation="wave" variant="text" width={60} />
        </Box>
        <Divider />
        <Skeleton animation="wave" variant="text" />
        <Skeleton animation="wave" variant="text" />
        <Skeleton animation="wave" variant="text" />
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Button variant="solid" size="sm">
                Explore
                <Skeleton animation="wave" />
            </Button>
        </Box>
    </Card>
);

const Feed = (): JSX.Element => {
    const { getToken } = useAuth();
    const [documents, setDocuments] = useState<Document[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [hasMoreData, setHasMoreData] = useState<boolean>(true);
    const [numberOfResults, setNumberOfResults] = useState<number>(0);
    const [viewMode, setViewMode] = useState<"normal" | "simple">(() => (localStorage.getItem("feedViewMode") as "normal" | "simple") || "normal");
    const [searchParams, setSearchParams] = useSearchParams();
    const search = useMemo(() => searchParams.get("search") || "", [searchParams]);

    const filters = useMemo(() => {
        const parsedFilters: Record<string, string[]> = {};
        searchParams.forEach((value, key) => {
            if (key !== "search") {
                if (!parsedFilters[key]) {
                    parsedFilters[key] = [];
                }
                parsedFilters[key].push(value);
            }
        });
        return parsedFilters;
    }, [searchParams]);

    useEffect(() => {
        setIsFetching(true);
        setDocuments([]);
        setHasMoreData(true);

        const fetchData = async () => {
            const request: SearchRequest = {
                search: search,
                context: {
                    overrides: {
                        filters: filters,
                        skip: 0
                    }
                }
            };

            const response = await searchRequest(
                request,
                await getToken({
                    template: "Standard"
                })
            );
            if (response.ok) {
                const resp = await response.json();
                setNumberOfResults(resp["total"]);
                setDocuments(resp["documents"]);
                setHasMoreData(resp["documents"].length > 0);
                setIsFetching(false);
            }
        };

        fetchData();
    }, [filters, search]);

    useEffect(() => {
        if (isFetching) return;

        if (page > 1 && hasMoreData) {
            setIsFetching(true);

            const fetchMoreData = async () => {
                const request: SearchRequest = {
                    search: search,
                    context: {
                        overrides: {
                            filters: filters,
                            skip: (page - 1) * 50
                        }
                    }
                };

                const response = await searchRequest(
                    request,
                    await getToken({
                        template: "Standard"
                    })
                );
                if (response.ok) {
                    const newDocs = (await response.json())["documents"];
                    if (newDocs.length === 0) {
                        setHasMoreData(false);
                    }
                    setDocuments(prevData => [...prevData, ...newDocs]);
                    setIsFetching(false);
                }
            };
            fetchMoreData();
        }
    }, [page]);

    useEffect(() => {
        const handleScroll = (event: Event) => {
            const target = event.target as HTMLElement;
            const { scrollTop, clientHeight, scrollHeight } = target;
            if (!isFetching && scrollTop + clientHeight >= scrollHeight - 100) {
                setPage(prevPage => prevPage + 1);
            }
        };

        const container = document.querySelector(`.${styles.container}`);
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [isFetching]);

    const handleSearchChange = (newSearch: string) => {
        setSearchParams(prevParams => {
            const params = new URLSearchParams(prevParams);

            // Update or delete the searchterm
            if (newSearch) {
                params.set("search", newSearch);
            } else {
                params.delete("search");
            }

            // Preserve all existing filter parameters
            Object.keys(filters).forEach(key => {
                filters[key].forEach(value => {
                    if (!params.has(key) || params.getAll(key).indexOf(value) === -1) {
                        params.append(key, value);
                    }
                });
            });

            return params;
        });
    };

    const handleFilterChange = (newFilters: FilterRequest) => {
        setSearchParams(prevParams => {
            const params = new URLSearchParams(prevParams);
            if (search) params.set("search", search); // Preserve the search term

            // Clear existing filters
            Object.keys(filters).forEach(key => {
                params.delete(key);
            });

            // Add updated filters
            for (const [key, values] of Object.entries(newFilters)) {
                values?.forEach(value => {
                    if (value) {
                        params.append(key, value);
                    }
                });
            }

            return params;
        });
    };
    return (
        <div className={styles.container}>
            <Filters
                includeSearch
                setSearch={handleSearchChange}
                searchTerm={search}
                filters={filters}
                setFilters={handleFilterChange}
                includeFilters={["jurisdiction", "court", "categories"]}
                layout="search-first"
                className={styles.feedHeader}
                viewMode={viewMode}
                initialQuestion={searchParams.get("search") || ""}
                onViewModeChange={(_, newMode) => {
                    if (newMode) {
                        setViewMode(newMode);
                        localStorage.setItem("feedViewMode", newMode);
                    }
                }}
            />
            <div className={styles.contentContainer}>
                {viewMode === "normal" ? (
                    <>
                        <Stack className={styles.FeedContainer} spacing={2}>
                            <Box sx={{ alignSelf: "flex-start" }}>
                                <Typography level={"body-xs"}>Approximately {numberOfResults.toLocaleString()} results </Typography>{" "}
                            </Box>
                            {documents.map((row: Document) => (
                                <Result key={row.name} row={row} />
                            ))}
                            {isFetching && Array.from({ length: 10 }).map((_, index) => <SkeletonResult key={index} />)}
                        </Stack>
                    </>
                ) : (
                    <div className={styles.tableContainer}>
                        <ResultsTable documents={documents} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Feed;
