import React from "react";
import { Button } from "@mui/joy";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { useNavigate } from "react-router-dom";

interface ChatPDFButtonProps {
    document: DocumentMinimumFIelds;
    mobileView?: boolean;
    variantOverride?: "plain" | "soft" | "outlined";
}

interface DocumentMinimumFIelds {
    doc_id?: string;
    pdf_url?: string;
    url?: string;
    // eslint-disable-next-line
    [key: string]: any;
}

export const ChatPDFButton: React.FC<ChatPDFButtonProps> = ({ document, mobileView, variantOverride }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/pdf-chat", {
            state: {
                pdfUrl: document.pdf_url || document.url,
                documentId: document.doc_id,
                documentName: document.name
            }
        });
    };
    return (
        <Button
            startDecorator={mobileView ? undefined : <ChatOutlinedIcon />}
            size="md"
            variant={variantOverride ? variantOverride : "plain"}
            onClick={handleClick}
        >
            {mobileView ? <ChatOutlinedIcon /> : "Chat PDF"}
        </Button>
    );
};
