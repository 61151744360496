import React, { useRef, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/joy";
import { PDFViewer, PDFViewerHandle } from "../../components/PDFViewer/PDFViewer";
import { QuestionInput } from "../../components/QuestionInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { UserChatMessage } from "../../components/UserChatMessage";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { Highlight, Quote } from "./types";
import { ChatAppResponse } from "../../api";
import styles from "./PDFChatView.module.css";
import loading_animation_1 from "../../assets/loading_animation_1.gif";
import loading_animation_2 from "../../assets/loading_animation_2.gif";
import loading_animation_3 from "../../assets/loading_animation_3.gif";
import { animations } from "../../util/utils";

interface PDFChatViewProps {
    pdfUrl: string;
    documentId: string;
    documentName: string;
    onClose: () => void;
    handleSendQuestion: (question: string) => void;
    answers: [string, ChatAppResponse][];
    isLoading: boolean;
    // eslint-disable-next-line
    error: any;
    quotes: Quote[];
    lastQuestionRef: React.MutableRefObject<string>;
}

export const PDFChatView: React.FC<PDFChatViewProps> = ({
    pdfUrl,
    documentName,
    onClose,
    handleSendQuestion,
    answers,
    isLoading,
    error,
    quotes,
    lastQuestionRef
}) => {
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const pdfViewerRef = useRef<PDFViewerHandle | null>(null);
    const mobilePdfViewerRef = useRef<PDFViewerHandle | null>(null);
    const hiddenPdfViewerRef = useRef<PDFViewerHandle | null>(null);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [gifsLoaded, setGifsLoaded] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 768 && hiddenPdfViewerRef.current) {
            // Force the hidden viewer to load the PDF immediately
            const hiddenViewer = document.querySelector('div[style*="display: none"] .rpv-core__viewer');
            if (hiddenViewer) {
                hiddenViewer.dispatchEvent(new Event("resize"));
            }
        }
    }, []);

    // Add GIF preloading
    useEffect(() => {
        const preloadGifs = async () => {
            const urls = [loading_animation_1, loading_animation_2, loading_animation_3];
            const loadedGifs = await Promise.all(
                urls.map(async url => {
                    const response = await fetch(url);
                    return URL.createObjectURL(await response.blob());
                })
            );
            animations.push(...loadedGifs);
            setGifsLoaded(true);
        };
        preloadGifs();
    }, []);

    const handleQuoteClick = (quote: Quote) => {
        const firstHighlight: Highlight = quote.highlights[0];
        if (firstHighlight) {
            const area = {
                pageIndex: firstHighlight.pageIndex,
                height: firstHighlight.height,
                left: firstHighlight.left,
                top: Math.max(firstHighlight.top - 15, 0),
                width: firstHighlight.width
            };

            // Check if we're on mobile (window width <= 768px)
            if (window.innerWidth <= 768) {
                // First ensure the hidden viewer has jumped to the right spot
                if (hiddenPdfViewerRef.current) {
                    hiddenPdfViewerRef.current.jumpToHighlightArea(area);
                }
                // Then show the modal
                setShowPdfModal(true);
                // Use a longer timeout to ensure PDF is fully loaded
                setTimeout(() => {
                    if (mobilePdfViewerRef.current) {
                        mobilePdfViewerRef.current.jumpToHighlightArea(area);
                    }
                }, 300); // Increased from 100ms to 300ms
            } else if (pdfViewerRef.current) {
                pdfViewerRef.current.jumpToHighlightArea(area);
            }
        }
    };

    useEffect(() => {
        if (isLoading) {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        } else {
            chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [answers, isLoading]);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.chatSection}>
                    <Box className={styles.headerSection}>
                        <Button onClick={onClose} startDecorator={<ArrowBackIcon />} size="sm" variant={"outlined"} sx={{ alignSelf: "flex-start", mb: 2 }}>
                            Back
                        </Button>
                        <Typography level="h4">Chat with {documentName}</Typography>
                    </Box>

                    <QuestionInput placeholder="Type your question here" onSend={handleSendQuestion} clearOnSend />
                    <div style={{ flex: 1, overflowY: "auto" }}>
                        {answers.map((answer, index) => (
                            <div key={index}>
                                <UserChatMessage message={answer[0]} />
                                <div>
                                    <Answer
                                        isStreaming={false}
                                        key={index}
                                        answer={answer[1]}
                                        chatType="pdf"
                                        isSelected={false}
                                        onCitationClicked={() => void 0}
                                        onSupportingContentClicked={() => void 0}
                                        onFollowupQuestionClicked={handleSendQuestion}
                                        showFollowupQuestions={true}
                                        onQuoteClick={handleQuoteClick}
                                    />
                                </div>
                            </div>
                        ))}
                        {isLoading && (
                            <>
                                <UserChatMessage message={lastQuestionRef.current} />
                                <Box sx={{ maxWidth: "500px" }}>
                                    <AnswerLoading gifsLoaded={gifsLoaded} />
                                </Box>
                            </>
                        )}
                        {error && (
                            <>
                                <UserChatMessage message={lastQuestionRef.current} />
                                <div>
                                    <AnswerError error={error.toString()} onRetry={() => handleSendQuestion(lastQuestionRef.current)} />
                                </div>
                            </>
                        )}
                        <div ref={chatMessageStreamEnd} />
                    </div>
                </div>
                <div className={styles.pdfSection}>
                    <PDFViewer fileUrl={pdfUrl} ref={pdfViewerRef} currentQuery={lastQuestionRef.current} quotes={quotes} />
                </div>
                <div style={{ display: "none" }}>
                    <PDFViewer fileUrl={pdfUrl} ref={hiddenPdfViewerRef} currentQuery={lastQuestionRef.current} quotes={quotes} />
                </div>
            </div>

            <div className={`${styles.pdfModal} ${showPdfModal ? styles.open : ""}`}>
                <Button
                    onClick={() => setShowPdfModal(false)}
                    className={styles.closeModalButton}
                    variant="outlined"
                    color="neutral"
                    startDecorator={<CloseIcon />}
                >
                    Close
                </Button>
                <div className={styles.pdfModalContent}>
                    <PDFViewer fileUrl={pdfUrl} ref={mobilePdfViewerRef} currentQuery={lastQuestionRef.current} quotes={quotes} />
                </div>
            </div>
        </>
    );
};
