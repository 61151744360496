import { useContext, useState } from "react";
import styles from "./QuestionInput.module.css";
import { Box, Textarea, Tooltip } from "@mui/joy";
import { useAuth, useClerk } from "@clerk/clerk-react";
import { UserContext } from "../../contexts/UserContext";
import { CheckoutModal } from "../Checkout";
import { ChatTypeSelector } from "../ChatTypeSelector";
import IconButton from "@mui/joy/IconButton";
import { ChevronRight, Search } from "@mui/icons-material";

interface Props {
    onSend: (question: string) => void;
    placeholder?: string;
    clearOnSend?: boolean;
    showChatSelect?: boolean;
    size?: "sm" | "md" | "lg";
    allowEmpty?: boolean;
    useSearchIcon?: boolean;
    initialQuestion?: string;
}

export const QuestionInput = ({ onSend, placeholder, clearOnSend, showChatSelect, size, allowEmpty, useSearchIcon, initialQuestion }: Props) => {
    const [openCheckout, setOpenCheckout] = useState<boolean>(false);
    const [question, setQuestion] = useState<string>(initialQuestion || "");
    const { isSignedIn } = useAuth();
    const { redirectToSignIn } = useClerk();
    const { user } = useContext(UserContext);
    const MAX_CHARS = 5000;

    const isChatDisabled = () => {
        return !isSignedIn || (user?.searches_left || 0) <= 0;
    };
    const sendQuestion = () => {
        if (isChatDisabled() || (!question.trim() && !allowEmpty)) {
            return;
        }
        onSend(question);
        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (value: string) => {
        if (!value) {
            setQuestion("");
        } else if (value.length <= MAX_CHARS) {
            setQuestion(value);
        }
    };

    return !isChatDisabled() ? (
        <Box className={styles.questionArea}>
            {showChatSelect && <ChatTypeSelector />}
            <Box className={styles.questionInputContainer}>
                <Textarea
                    maxRows={6}
                    size={size || "lg"}
                    className={styles.questionInputTextArea}
                    placeholder={placeholder}
                    onChange={event => onQuestionChange(event.target.value)}
                    onKeyDown={onEnterPress}
                    value={question}
                    disabled={!isSignedIn}
                    endDecorator={
                        <Box className={styles.endDecorator}>
                            <IconButton color={"primary"} variant={"plain"} onClick={() => sendQuestion()}>
                                {useSearchIcon ? <Search /> : <ChevronRight />}
                            </IconButton>
                        </Box>
                    }
                />
            </Box>
        </Box>
    ) : (
        <Box className={styles.questionArea}>
            {showChatSelect && <ChatTypeSelector />}
            <Tooltip title={!isSignedIn ? "Please sign in" : "You are out of searches for this month."} followCursor={true}>
                <Box
                    className={styles.questionInputContainer}
                    onClick={() => {
                        if (!isSignedIn) redirectToSignIn();
                        if (isSignedIn && user?.searches_left === 0) setOpenCheckout(true);
                    }}
                >
                    <Textarea
                        maxRows={6}
                        size={size || "lg"}
                        className={styles.questionInputTextArea}
                        placeholder={placeholder}
                        onChange={event => onQuestionChange(event.target.value)}
                        onKeyDown={onEnterPress}
                        value={question}
                        disabled={!isSignedIn}
                    />
                </Box>
            </Tooltip>
            <CheckoutModal isOpen={openCheckout} toggleModal={() => setOpenCheckout(!openCheckout)} userEmail={user?.email || ""} />
        </Box>
    );
};
