import React, { useState, useEffect } from "react";
import { Box, List, ListItem, ListSubheader, Typography, Tooltip, ButtonGroup, Button } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import styles from "./HistoryMenu.module.css";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { FavoriteButton } from "../FavoriteButton/FavoriteButton";
import { Conversation, ConversationMap } from "../Sidebar";

interface HistoryMenuProps {
    open: boolean;
    onClose: () => void;
    groupedConversations: ConversationMap;
    onFavoriteChange?: (convoId: string, isFavorite: boolean) => void;
}

type ViewMode = "recent" | "starred";

export const HistoryMenu: React.FC<HistoryMenuProps> = ({ open, onClose, groupedConversations, onFavoriteChange }) => {
    const navigate = useNavigate();
    const [viewMode, setViewMode] = useState<ViewMode>("recent");
    const [localConversations, setLocalConversations] = useState(groupedConversations);

    useEffect(() => {
        setLocalConversations(groupedConversations);
    }, [groupedConversations]);

    const handleFavoriteChange = (convoId: string, isFavorite: boolean) => {
        const updatedConversations = { ...localConversations };
        Object.keys(updatedConversations).forEach(date => {
            updatedConversations[date] = updatedConversations[date].map(convo => (convo.id === convoId ? { ...convo, favorite: isFavorite } : convo));
        });
        setLocalConversations(updatedConversations);

        onFavoriteChange?.(convoId, isFavorite);
    };

    const filteredConversations = React.useMemo(() => {
        if (viewMode === "starred") {
            return Object.keys(localConversations).reduce(
                (acc, date) => {
                    const starredConvos = localConversations[date].filter(convo => convo.favorite);
                    if (starredConvos.length > 0) {
                        acc[date] = starredConvos;
                    }
                    return acc;
                },
                {} as Record<string, Conversation[]>
            );
        }
        return localConversations;
    }, [localConversations, viewMode]);

    if (!open) return null;

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Box
                className={styles.historyMenu}
                sx={{
                    position: "fixed",
                    left: "220px",
                    top: 0,
                    height: "100vh",
                    width: "300px",
                    backgroundColor: "background.surface",
                    borderRight: "1px solid",
                    borderColor: "divider",
                    overflowY: "auto",
                    zIndex: 1100,
                    boxShadow: "rgba(0, 0, 0, 0.1) 2px 0px 4px"
                }}
            >
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "background.surface",
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        p: 2,
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    <ButtonGroup variant="soft" size="sm">
                        <Button
                            onClick={() => setViewMode("recent")}
                            variant={viewMode === "recent" ? "solid" : "soft"}
                            color={viewMode === "recent" ? "primary" : "neutral"}
                        >
                            Recent
                        </Button>
                        <Button
                            onClick={() => setViewMode("starred")}
                            variant={viewMode === "starred" ? "solid" : "soft"}
                            color={viewMode === "starred" ? "primary" : "neutral"}
                        >
                            Starred
                        </Button>
                    </ButtonGroup>
                </Box>
                <Box
                    className={`${styles.chatHistory} ${styles.chatHistoryScrollbar}`}
                    sx={{
                        height: "calc(100% - 64px)", // Adjust for the toggle header
                        overflowY: "auto",
                        overflowX: "hidden",
                        p: 2,
                        backgroundColor: "var(--joy-palette-background-surface)"
                    }}
                >
                    <List>
                        {Object.keys(filteredConversations).map((date: string) => (
                            <ListItem nested key={date}>
                                <ListSubheader>{date}</ListSubheader>
                                <List>
                                    {filteredConversations[date].map((convo: Conversation) => (
                                        <ListItem key={convo.id} sx={{ mt: 0.25 }}>
                                            {convo.messages[0]?.question && (
                                                <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                                                    <FavoriteButton
                                                        id={convo.id}
                                                        type="conversation"
                                                        initialFavoriteState={convo.favorite || false}
                                                        size="sm"
                                                        className={styles.historyFavoriteButton}
                                                        onFavoriteChange={isFavorite => handleFavoriteChange(convo.id, isFavorite)}
                                                    />
                                                    <Tooltip title={convo.messages[0]?.question} placement="right">
                                                        <Box
                                                            onClick={() => {
                                                                navigate(
                                                                    convo.type === "pdf"
                                                                        ? `/pdf-chat/?conversationId=${convo.id}`
                                                                        : `/?conversationId=${convo.id}`,
                                                                    { state: { pdfUrl: convo.url, documentId: convo.doc_id } }
                                                                );
                                                                onClose();
                                                            }}
                                                            sx={{
                                                                cursor: "pointer",
                                                                p: 1,
                                                                borderRadius: 1,
                                                                width: "100%",
                                                                transition: "background-color 0.2s ease",
                                                                "&:hover": {
                                                                    backgroundColor: "var(--joy-palette-neutral-100, #f0f4f8)"
                                                                }
                                                            }}
                                                        >
                                                            <Typography noWrap={true} level="body-sm">
                                                                {convo.messages[0]?.question}
                                                            </Typography>
                                                        </Box>
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </ListItem>
                        ))}
                        {viewMode === "starred" && Object.keys(filteredConversations).length === 0 && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100px"
                                }}
                            >
                                <Typography level="body-sm" color="neutral">
                                    No starred conversations yet
                                </Typography>
                            </Box>
                        )}
                    </List>
                </Box>
            </Box>
        </ClickAwayListener>
    );
};
