import { Pivot, PivotItem } from "@fluentui/react";
import { SupportingContent } from "../SupportingContent";
import { ChatAppResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: ChatAppResponse;
}

export const AnalysisPanel = ({ answer, activeTab, className, onActiveTabChanged }: Props): JSX.Element => {
    // Just use the documents from the answer
    const uniqueDocs = answer.documents || [];

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Sources"
                headerButtonProps={!uniqueDocs.length ? { disabled: true, style: { color: "grey" } } : undefined}
            >
                <SupportingContent supportingContent={answer.choices[0].context.data_points || []} documents={uniqueDocs} />
            </PivotItem>
        </Pivot>
    );
};
